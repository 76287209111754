import { Button, styled } from "@mui/material";

const RSDButtonComponent =(props) =>
{
    console.log(props);
    const RSDbutton = styled (Button)(()=>
        ({
            fontFamily:'Audiowide',
            color:'#fff',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#333',
            },
        }));
    return(<RSDbutton variant="text">{props.page}</RSDbutton>)       
}

export default  RSDButtonComponent;