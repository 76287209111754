import styles from './Apps.module.css';
import logo from './images/Logo.png';

const Home =() =>
{
    return(<>
    <div className={styles.homecontent}>
    <div className={styles.homepagediv}>
        <div>            
        <h1>Welcome to RSD IT Services</h1>        
        <h3>Empowering Your Business in the Digital World</h3>
        <h4>At <b>RSD IT Services</b>, we are dedicated to helping businesses transition seamlessly into the digital age. By reducing manual work and implementing automation, we streamline your operations, saving you time and resources while driving efficiency.</h4>
        <h3>Your Success is Our Focus</h3>
        <h4>We believe that every business is unique, and we take pride in building solutions from the ground up to match your specific needs. Our team works with precision, focusing on every detail to deliver exceptional results. Whether you’re starting from scratch or improving existing systems, we guide you toward success with innovative digital solutions tailored for your business.</h4>
        <h4>Let us take care of your technology, so you can focus on what matters most—growing your business.</h4> 
        <h2>Let’s work together to bring your business to the digital world.</h2>
        </div>
    </div>
        <img src = {logo} className={styles.applogohome} alt="logo"/>
    </div>
 
    </>)
}

export default Home;