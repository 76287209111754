import React,{useEffect} from 'react';
import styles from './Apps.module.css';
import Header from './Header';
import Home from './Home';

const BasePage =() =>
{
    
useEffect(() => {
    document.title = 'RSD IT Services';
  }, []);

    return(   
    <> 
    <div className={styles.background}></div>
    <Header pagename="Home"></Header>
    <Home></Home>
    </>
    )
}

export default BasePage;        