import logo from "./images/Logo.png";
import styles from './Apps.module.css';
import RSDButtonComponent from './StyledButton';
  
const pages = ['Services', 'About', 'Contact Us', 'Home'];

const Header = (props) =>
{
    return( <>
   <div>
      <header>
        <nav className={styles.navbar}>
        <div className={styles.headerlogotitle}>
            <img src={logo} className={styles.applogo}></img>
            <h1 className={styles.headertext}>RSD IT SERVICES</h1>
        </div>
        <ul className={styles.navlinks}>
          {pages.map((page) => {
            if(props.pagename !== page )
            return(
                <RSDButtonComponent page ={page}></RSDButtonComponent>  )     
             })}
        </ul>
        </nav>
      </header>
    </div>
    </>
      )
}
export default Header;